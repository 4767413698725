<template>
  <div>
    <van-form @submit="onSubmit">
      <van-field v-model="hzlist.HouserStatusName"
        label="户口状态"
        placeholder="请选择户口状态"
        @click="hukouPicker = true"
        readonly="readonly"
        clickable
        required />
      <van-popup v-model="hukouPicker"
        position="bottom">
        <van-picker show-toolbar
          :columns="hukou"
          value-key="Name"
          @confirm="hukouQr"
          @cancel="guanxiPicker = false" />
      </van-popup>
      <van-field v-model="hzlist.RelationHouserName"
        label="户主关系"
        placeholder="请选择户主关系"
        @click="guanxiPicker = true"
        readonly="readonly"
        clickable
        required />
      <van-popup v-model="guanxiPicker"
        position="bottom">
        <van-picker show-toolbar
          :columns="guanxi"
          value-key="Name"
          @confirm="guanxiQr"
          @cancel="guanxiPicker = false" />
      </van-popup>
      <van-field v-model="hzlist.Name"
        name="Name"
        label="姓名"
        placeholder="姓名"
        required>
        <template #button>
          <van-uploader :after-read="afterReadBaidu">
            <van-icon name="scan"
              size="27" />
          </van-uploader>
        </template>
      </van-field>
      <van-field v-model="hzlist.MobileTel"
        name="MobileTel"
        label="联系电话"
        placeholder="联系电话" />
      <van-field v-model="hzlist.IdNumber"
        name="IdNumber"
        required
        label="身份证号"
        placeholder="身份证号"
        @blur="IdNum(hzlist.IdNumber)" />
      <van-field v-model="hzlist.Birthday"
        name="Birthday"
        label="出生日期"
        @click="recumenFend = true"
        readonly="readonly"
        placeholder="请选择出生日期" />
      <van-popup v-model="recumenFend"
        position="bottom"
        get-container="body">
        <van-datetime-picker v-model="currentDate"
          show-toolbar
          type="date"
          title="请选择出生日期"
          :min-date="minDate"
          :max-date="maxDate"
          @cancel="recumenFend = false"
          @confirm="onrecumenFend">
        </van-datetime-picker>
      </van-popup>
      <van-field name="Sex"
        label="性别">
        <template #input>
          <van-radio-group v-model="hzlist.Sex"
            direction="horizontal">
            <van-radio name="1">男</van-radio>
            <van-radio name="2">女</van-radio>
          </van-radio-group>
        </template>
      </van-field>

      <van-field v-model="hzlist.NationName"
        label="民族"
        placeholder="请选择所属民族"
        @click="minzuPicker = true"
        readonly="readonly"
        clickable />
      <van-popup v-model="minzuPicker"
        position="bottom">
        <van-picker show-toolbar
          :columns="minzu"
          value-key="Name"
          @confirm="onrecumenANation"
          @cancel="minzuPicker = false" />
      </van-popup>

      <van-field v-model="hzlist.EducationName"
        label="学历"
        placeholder="请选择学历"
        @click="xueliPicker = true"
        readonly="readonly"
        clickable />
      <van-popup v-model="xueliPicker"
        position="bottom">
        <van-picker show-toolbar
          :columns="xveli"
          value-key="Name"
          @confirm="xueliList"
          @cancel="xueliPicker = false" />
      </van-popup>
      <van-field v-model="hzlist.ReligionName"
        label="宗教信仰"
        placeholder="请选择宗教信仰"
        @click="zongjiaoPicker = true"
        readonly="readonly"
        clickable />
      <van-popup v-model="zongjiaoPicker"
        position="bottom">
        <van-picker show-toolbar
          :columns="zongjiao"
          value-key="Name"
          @confirm="zongjiaoList"
          @cancel="zongjiaoPicker = false" />
      </van-popup>

      <van-field v-model="hzlist.MarriageName"
        label="婚姻状态"
        placeholder="请选择婚姻状态"
        @click="hunyinPicker = true"
        readonly="readonly"
        clickable />
      <van-popup v-model="hunyinPicker"
        position="bottom">
        <van-picker show-toolbar
          :columns="hunyin"
          value-key="Name"
          @confirm="hunyinList"
          @cancel="hunyinPicker = false" />
      </van-popup>
      <van-field v-model="hzlist.PoliticalName"
        label="政治面貌"
        placeholder="请选择政治面貌"
        @click="zhengzhiPicker = true"
        readonly="readonly"
        clickable />
      <van-popup v-model="zhengzhiPicker"
        position="bottom">
        <van-picker show-toolbar
          :columns="zhengzhi"
          value-key="Name"
          @confirm="zhengzhiQr"
          @cancel="zhengzhiPicker = false" />
      </van-popup>
      <van-field name="HealthyCircs"
        label="健康状况">
        <template #input>
          <van-radio-group v-model="hzlist.HealthyCircs"
            direction="horizontal">
            <van-radio name="1">健康</van-radio>
            <van-radio name="2">疾病</van-radio>
          </van-radio-group>
        </template>
      </van-field>

      <van-field v-model="hzlist.DiseaseCircs"
        v-if="hzlist.HealthyCircs == 2"
        label="疾病情况"
        placeholder="请选择疾病情况"
        @click="jibingPicker = true"
        readonly="readonly"
        clickable />
      <van-popup v-model="jibingPicker"
        position="bottom">
        <van-picker show-toolbar
          :columns="jibingList"
          value-key="label"
          @confirm="jibingQr"
          @cancel="jibingPicker = false" />
      </van-popup>

      <van-field v-if="hzlist.DiseaseCircs == '09'"
        v-model="hzlist.OtherDisease"
        name="OtherDisease"
        label="其他疾病"
        placeholder="请输入其他疾病" />
      <van-field v-model="hzlist.RegisterAddr"
        name="RegisterAddr"
        label="户籍地"
        type="textarea"
        autosize
        placeholder="请输入户籍地" />
      <van-field name="IsOnlyChild"
        label="独生子女">
        <template #input>
          <van-radio-group v-model="hzlist.IsOnlyChild"
            direction="horizontal">
            <van-radio name="1">是</van-radio>
            <van-radio name="2">否</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <van-field name="Gravida"
        label="孕产妇">
        <template #input>
          <van-radio-group v-model="hzlist.Gravida"
            direction="horizontal">
            <van-radio name="1">是</van-radio>
            <van-radio name="2">否</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <van-field name="Teenagers"
        label="青少年">
        <template #input>
          <van-radio-group v-model="hzlist.Teenagers"
            direction="horizontal">
            <van-radio name="1">是</van-radio>
            <van-radio name="2">否</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <van-field name="PlannedBirth"
        label="计划生育">
        <template #input>
          <van-radio-group v-model="hzlist.PlannedBirth"
            direction="horizontal">
            <van-radio name="1">是</van-radio>
            <van-radio name="2">否</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <van-field name="LonelyOlder"
        label="孤寡老人">
        <template #input>
          <van-radio-group v-model="hzlist.LonelyOlder"
            direction="horizontal">
            <van-radio name="1">是</van-radio>
            <van-radio name="2">否</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <van-field name="EmptyOlder"
        label="空巢老人">
        <template #input>
          <van-radio-group v-model="hzlist.EmptyOlder"
            direction="horizontal">
            <van-radio name="1">是</van-radio>
            <van-radio name="2">否</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <van-field name="AloneOlder"
        label="独居老人">
        <template #input>
          <van-radio-group v-model="hzlist.AloneOlder"
            direction="horizontal">
            <van-radio name="1">是</van-radio>
            <van-radio name="2">否</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <van-field name="Disability"
        label="失能">
        <template #input>
          <van-radio-group v-model="hzlist.Disability"
            direction="horizontal">
            <van-radio name="1">是</van-radio>
            <van-radio name="2">否</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <van-field name="HDisability"
        label="半失能">
        <template #input>
          <van-radio-group v-model="hzlist.HDisability"
            direction="horizontal">
            <van-radio name="1">是</van-radio>
            <van-radio name="2">否</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <van-field v-model="hzlist.MilitaryName"
        label="兵役情况"
        placeholder="请选择兵役情况"
        @click="bingyiPicker = true"
        readonly="readonly"
        clickable />

      <van-popup v-model="bingyiPicker"
        position="bottom">
        <van-picker show-toolbar
          :columns="bingyi"
          value-key="Name"
          @confirm="bingyiQr"
          @cancel="bingyiPicker = false" />
      </van-popup>

      <van-field v-model="hzlist.Company"
        name="Company"
        label="工作单位"
        placeholder="请输入工作单位" />

      <van-field v-model="hzlist.Interest"
        name="Interest"
        label="兴趣爱好"
        placeholder="请输入兴趣爱好" />
      <van-field v-model="hzlist.Speciality"
        name="Interest"
        label="特长"
        placeholder="请输入特长" />

      <van-field name="IsPartIn"
        label="社区活动">
        <template #input>
          <van-radio-group v-model="hzlist.IsPartIn"
            direction="horizontal">
            <van-radio name="1">愿意</van-radio>
            <van-radio name="2">不愿意</van-radio>
          </van-radio-group>
        </template>
      </van-field>

      <van-field v-model="hzlist.NationalityName"
        label="国籍"
        placeholder="请选择所属国籍"
        @click="guojiaPicker = true"
        readonly="readonly"
        clickable />
      <van-popup v-model="guojiaPicker"
        position="bottom">
        <van-picker show-toolbar
          :columns="guojia"
          value-key="Name"
          @confirm="guojiaQr"
          @cancel="guojiaPicker = false" />
      </van-popup>

      <van-field v-model="hzlist.WorkStatusName"
        label="从业状况"
        placeholder="请选择从业状况"
        @click="congyePicker = true"
        readonly="readonly"
        clickable />
      <van-popup v-model="congyePicker"
        position="bottom">
        <van-picker show-toolbar
          :columns="congye"
          value-key="Name"
          @confirm="congyeQr"
          @cancel="congyePicker = false" />
      </van-popup>
      <!-- <van-field v-model="hzlist.RegisterAddr"
        rows="1"
        autosize
        type="textarea"
        name="RegisterAddr"
        label="户籍地址"
        placeholder="请输入户籍地址" /> -->

      <van-field v-model="hzlist.OtherAddr"
        rows="1"
        autosize
        type="textarea"
        name="OtherAddr"
        label="其他住址"
        placeholder="请输入其他住址" />

      <van-field v-model="hzlist.Email"
        name="Email"
        label="Email"
        placeholder="请输入Email" />
      <van-field v-model="hzlist.Tel"
        name="Tel"
        label="固定电话"
        placeholder="请输入固定电话" />
      <van-field v-model="hzlist.PostCode"
        name="PostCode"
        label="邮编"
        placeholder="请输入邮编" />

      <van-field v-model="hzlist.School"
        name="School"
        label="毕业院校"
        placeholder="请输入毕业院校" />
      <van-field v-model="hzlist.OtherWorkStatus"
        name="OtherWorkStatus"
        label="其他状态"
        placeholder="请输入其他状态" />

      <!-- <van-field name="ForbidReason" label="禁用原因">
        <template #input>
          <van-radio-group v-model="hzlist.ForbidReason" direction="horizontal">
            <van-radio name="1">签出</van-radio>
            <van-radio name="2">去世</van-radio>
            <van-radio name="3">其他</van-radio>
          </van-radio-group>
        </template>
      </van-field> -->
      <van-field name="Status"
        label="状态">
        <template #input>
          <van-radio-group v-model="hzlist.Status"
            direction="horizontal">
            <van-radio name="1">启用</van-radio>
            <van-radio name="2">禁用</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <!-- <van-field
        v-model="hzlist.ForbidRemark"
        name="ForbidRemark"
        label="其他原因"
        placeholder="请输入其他禁用原因"
      /> -->
      <van-field v-model="hzlist.Remark"
        rows="1"
        label="备注"
        autosize
        type="textarea"
        placeholder="请输入备注" />
      <!-- <div style="width: 93%; margin: 0 auto;text-align: center;" class="renType" v-if="RyType != 1"> -->
      <div class="renType"
        v-if="RyType != 1">
        <van-field v-model="hzlist.ryTypeName"
          label="人员类型"
          placeholder="请选择人员类型"
          @click="ryTypePicker = true"
          readonly="readonly"
          clickable />
        <van-popup v-model="ryTypePicker"
          position="bottom">
          <van-picker show-toolbar
            :columns="ryTypeList"
            value-key="label"
            @confirm="ryTypeQr"
            @cancel="ryTypePicker = false" />
        </van-popup>

        <!-- <van-button color="#F56C6C" size="small" round @click="SaveTk('1')"
          >特困人员</van-button
        >
        <van-button color="#E6A23C" size="small" round @click="SaveTk('2')"
          >低保人员</van-button
        >
        <van-button color="#409EFF" size="small" round @click="SaveTk('3')"
          >廉租房</van-button
        >
        <van-button color="#7232dd" size="small" round @click="SaveTk('4')"
          >特扶人员</van-button
        >
        <van-button color="#ff976a" size="small" round @click="SaveTk('5')"
          >党员</van-button
        >
        <van-button color="#67C23A" size="small" round @click="SaveTk('6')"
          >残疾人员</van-button
        > -->
      </div>
      <!-- 特困人员 -->
      <div v-if="TkRenYuan">
        <van-form>
          <van-field v-model="glForm.ProviderName"
            name="ProviderName"
            label="供养人姓名"
            placeholder="请输入供养人姓名" />
          <van-field v-model="glForm.ProviderTel"
            name="ProviderTel"
            label="联系电话"
            placeholder="请输入联系电话" />
          <van-field v-model="glForm.Relation"
            name="Relation"
            label="供养关系"
            placeholder="请输入与供养人关系" />
        </van-form>
      </div>
      <!-- 低保人员 -->
      <div v-if="DbRenYuan">
        <van-form>
          <van-field v-model="dbForm.StartTime"
            name="StartTime"
            label="开始时间"
            @click="StartTimeFend = true"
            readonly="readonly"
            placeholder="请选择开始时间" />
          <van-popup v-model="StartTimeFend"
            position="bottom"
            get-container="body">
            <van-datetime-picker show-toolbar
              type="date"
              title="请选择开始时间"
              :min-date="minDateTk"
              :max-date="maxDateTk"
              @cancel="StartTimeFend = false"
              @confirm="onStartTimeFend">
            </van-datetime-picker>
          </van-popup>
          <van-field v-model="dbForm.EndTime"
            name="EndTime"
            label="结束时间"
            @click="EndTimeFend = true"
            readonly="readonly"
            placeholder="请选择结束时间" />
          <van-popup v-model="EndTimeFend"
            position="bottom"
            get-container="body">
            <van-datetime-picker show-toolbar
              type="date"
              title="请选择结束时间"
              :min-date="minDateTk"
              :max-date="maxDateTk"
              @cancel="EndTimeFend = false"
              @confirm="onEndTimeFend">
            </van-datetime-picker>
          </van-popup>
          <van-field v-model="dbForm.Circls"
            rows="1"
            label="情况描述"
            autosize
            type="textarea"
            placeholder="请输入情况描述" />
        </van-form>
      </div>
      <div v-if="LzFang">
        <van-form>
          <van-field v-model="lzForm.LzFName"
            label="租户类别"
            placeholder="请选择租户类别"
            @click="zhLbPicker = true"
            readonly="readonly"
            clickable />
          <van-popup v-model="zhLbPicker"
            position="bottom">
            <van-picker show-toolbar
              :columns="lzfList"
              value-key="label"
              @confirm="lianZuFQr"
              @cancel="zhLbPicker = false" />
          </van-popup>
          <van-field v-model="lzForm.RoomAddr"
            rows="1"
            label="情况描述"
            autosize
            type="textarea"
            placeholder="请输入情况描述" />
        </van-form>
      </div>

      <div v-if="tfFang">
        <van-form>
          <van-field v-model="tfForm.tfName"
            label="特抚种类"
            placeholder="请选择特抚种类"
            @click="tfPicker = true"
            readonly="readonly"
            clickable />
          <van-popup v-model="tfPicker"
            position="bottom">
            <van-picker show-toolbar
              :columns="tfList"
              value-key="label"
              @confirm="tfRenyuanQr"
              @cancel="tfPicker = false" />
          </van-popup>
          <van-field v-model="tfForm.Remark"
            rows="1"
            label="特抚人员备注"
            autosize
            type="textarea"
            placeholder="请输入特抚人员备注" />
        </van-form>
      </div>

      <div v-if="dangyuanShow">
        <van-form>
          <van-field v-model="dyForm.PMName"
            label="党员类别"
            placeholder="请选择党员类别"
            @click="dyPicker = true"
            readonly="readonly"
            clickable />
          <van-popup v-model="dyPicker"
            position="bottom">
            <van-picker show-toolbar
              :columns="dyList"
              value-key="Name"
              @confirm="dangyuanQr"
              @cancel="dyPicker = false" />
          </van-popup>
          <van-field v-model="dyForm.InPartyTime"
            name="InPartyTime"
            label="入党时间"
            @click="InPartyTimeFend = true"
            readonly="readonly"
            placeholder="请选择入党时间" />
          <van-popup v-model="InPartyTimeFend"
            position="bottom"
            get-container="body">
            <van-datetime-picker show-toolbar
              type="date"
              title="请选择入党时间"
              :min-date="minDateTk"
              :max-date="maxDateTk"
              @cancel="InPartyTimeFend = false"
              @confirm="onInPartyTimeFend">
            </van-datetime-picker>
          </van-popup>
          <van-field v-model="dyForm.RelationAddr"
            rows="1"
            label="党关系所在地"
            autosize
            type="textarea"
            placeholder="请输入党组关系所在地" />
        </van-form>
      </div>

      <!-- 残疾人员 -->
      <div v-if="cjShow">
        <van-form>
          <van-field name="IsLimbs"
            label="肢体残疾">
            <template #input>
              <van-radio-group v-model="cjForm.IsLimbs"
                direction="horizontal">
                <van-radio name="1">是</van-radio>
                <van-radio name="2">否</van-radio>
              </van-radio-group>
            </template>
          </van-field>
          <van-field v-model="cjForm.LimbsLevel"
            name="LimbsLevel"
            label="肢体残疾等级"
            placeholder="请输入肢体残疾等级"
            v-if="cjForm.IsLimbs == 1" />
          <van-field name="IsSpirit"
            label="精神残疾">
            <template #input>
              <van-radio-group v-model="cjForm.IsSpirit"
                direction="horizontal">
                <van-radio name="1">是</van-radio>
                <van-radio name="2">否</van-radio>
              </van-radio-group>
            </template>
          </van-field>
          <van-field v-model="cjForm.SpiritLevel"
            name="ProviderName"
            label="精神残疾等级"
            placeholder="请输入精神残疾等级"
            v-if="cjForm.IsSpirit == 1" />
          <van-field name="IsVision"
            label="视力残疾">
            <template #input>
              <van-radio-group v-model="cjForm.IsVision"
                direction="horizontal">
                <van-radio name="1">是</van-radio>
                <van-radio name="2">否</van-radio>
              </van-radio-group>
            </template>
          </van-field>
          <van-field v-model="cjForm.VisionLevel"
            name="VisionLevel"
            label="视力残疾等级"
            placeholder="请输入视力残疾等级"
            v-if="cjForm.IsVision == 1" />
          <van-field name="IsHearing"
            label="听力残疾">
            <template #input>
              <van-radio-group v-model="cjForm.IsHearing"
                direction="horizontal">
                <van-radio name="1">是</van-radio>
                <van-radio name="2">否</van-radio>
              </van-radio-group>
            </template>
          </van-field>
          <van-field v-model="cjForm.HearingLevel"
            name="VisionLevel"
            label="听力残疾等级"
            placeholder="请输入听力残疾等级"
            v-if="cjForm.IsHearing == 1" />
          <van-field name="IsIntellect"
            label="智力残疾">
            <template #input>
              <van-radio-group v-model="cjForm.IsIntellect"
                direction="horizontal">
                <van-radio name="1">是</van-radio>
                <van-radio name="2">否</van-radio>
              </van-radio-group>
            </template>
          </van-field>
          <van-field v-model="cjForm.IntellectLevel"
            name="VisionLevel"
            label="智力残疾等级"
            placeholder="请输入智力残疾等级"
            v-if="cjForm.IsIntellect == 1" />
          <van-field name="IsLanguage"
            label="语言残疾">
            <template #input>
              <van-radio-group v-model="cjForm.IsLanguage"
                direction="horizontal">
                <van-radio name="1">是</van-radio>
                <van-radio name="2">否</van-radio>
              </van-radio-group>
            </template>
          </van-field>
          <van-field v-model="cjForm.LanguageLevel"
            name="VisionLevel"
            label="语言残疾等级"
            placeholder="请输入语言残疾等级"
            v-if="cjForm.IsLanguage == 1" />
        </van-form>
      </div>
      <!-- 退役军人 -->
      <div v-if="twShow">
        <van-form>
          <van-field v-model="twForm.JoinArmyTime"
            name="JoinArmyTime"
            label="入伍时间"
            @click="JoinArmyTimeFend = true"
            readonly="readonly"
            placeholder="请选择入伍时间" />
          <van-popup v-model="JoinArmyTimeFend"
            position="bottom"
            get-container="body">
            <van-datetime-picker show-toolbar
              type="date"
              title="请选择入伍时间"
              :min-date="minDateTk"
              :max-date="maxDateTk"
              @cancel="JoinArmyTimeFend = false"
              @confirm="onJoinArmyTimeFend">
            </van-datetime-picker>
          </van-popup>
          <van-field v-model="twForm.LeaveArmyTime"
            name="LeaveArmyTime"
            label="退伍时间"
            @click="LeaveArmyTimeFend = true"
            readonly="readonly"
            placeholder="请选择退伍时间" />
          <van-popup v-model="LeaveArmyTimeFend"
            position="bottom"
            get-container="body">
            <van-datetime-picker show-toolbar
              type="date"
              title="请选择退伍时间"
              :min-date="minDateTk"
              :max-date="maxDateTk"
              @cancel="LeaveArmyTimeFend = false"
              @confirm="onLeaveArmyTimeFend">
            </van-datetime-picker>
          </van-popup>
        </van-form>
      </div>
      <!-- 大学生 -->
      <div v-if="studentShow">
        <van-form>
          <van-field v-model="studentForm.AttendStage"
            label="就读阶段"
            placeholder="请选择就读阶段"
            @click="studentPicker = true"
            readonly="readonly"
            clickable />
          <van-popup v-model="studentPicker"
            position="bottom">
            <van-picker show-toolbar
              :columns="studentList"
              value-key="label"
              @confirm="studentQr"
              @cancel="studentPicker = false" />
          </van-popup>
          <van-field v-model="studentForm.InTime"
            name="InTime"
            label="入学时间"
            @click="InTimeFend = true"
            readonly="readonly"
            placeholder="请选择入学时间" />
          <van-popup v-model="InTimeFend"
            position="bottom"
            get-container="body">
            <van-datetime-picker show-toolbar
              type="date"
              title="请选择入学时间"
              :min-date="minDateTk"
              :max-date="maxDateTk"
              @cancel="InTimeFend = false"
              @confirm="onInTimeFend">
            </van-datetime-picker>
          </van-popup>
          <van-field v-model="studentForm.School"
            name="School"
            label="就读学校"
            placeholder="请输入就读学校" />
        </van-form>
      </div>
      <!-- 信访人员 -->
      <div v-if="xfShow">
        <van-form>
          <van-field v-model="xfForm.VisitsCircls"
            name="VisitsCircls"
            label="信访情况"
            placeholder="请输入信访情况" />
          <van-field v-model="xfForm.StableCircls"
            name="StableCircls"
            label="稳控情况"
            placeholder="请输入稳控情况" />
        </van-form>
      </div>
      <!-- 矫正人员 -->
      <div v-if="jzShow">
        <van-form>
          <van-field v-model="jzForm.CrimeCircls"
            name="CrimeCircls"
            label="犯罪情况"
            placeholder="请输入犯罪情况" />
          <van-field v-model="jzForm.RecviceTime"
            name="RecviceTime"
            label="接收时间"
            @click="jzRecviceTimeFend = true"
            readonly="readonly"
            placeholder="请选择接收时间" />
          <van-popup v-model="jzRecviceTimeFend"
            position="bottom"
            get-container="body">
            <van-datetime-picker show-toolbar
              type="date"
              title="请选择接收时间"
              :min-date="minDateTk"
              :max-date="maxDateTk"
              @cancel="jzRecviceTimeFend = false"
              @confirm="onjzRecviceTimeFend">
            </van-datetime-picker>
          </van-popup>
          <van-field v-model="jzForm.RelieveTime"
            name="RelieveTime"
            label="解除时间"
            @click="jzRelieveTimeFend = true"
            readonly="readonly"
            placeholder="请选择解除时间" />
          <van-popup v-model="jzRelieveTimeFend"
            position="bottom"
            get-container="body">
            <van-datetime-picker show-toolbar
              type="date"
              title="请选择解除时间"
              :min-date="minDateTk"
              :max-date="maxDateTk"
              @cancel="jzRelieveTimeFend = false"
              @confirm="onjzRelieveTimeFend">
            </van-datetime-picker>
          </van-popup>
        </van-form>
      </div>
      <!-- 刑释人员 -->
      <div v-if="xsShow">
        <van-form>
          <van-field v-model="xsForm.RecviceTime"
            name="RecviceTime"
            label="接收时间"
            @click="RecviceTimeFend = true"
            readonly="readonly"
            placeholder="请选择接收时间" />
          <van-popup v-model="RecviceTimeFend"
            position="bottom"
            get-container="body">
            <van-datetime-picker show-toolbar
              type="date"
              title="请选择接收时间"
              :min-date="minDateTk"
              :max-date="maxDateTk"
              @cancel="RecviceTimeFend = false"
              @confirm="onRecviceTimeFend">
            </van-datetime-picker>
          </van-popup>
          <van-field v-model="xsForm.RelieveTime"
            name="RelieveTime"
            label="解除时间"
            @click="RelieveTimeFend = true"
            readonly="readonly"
            placeholder="请选择解除时间" />
          <van-popup v-model="RelieveTimeFend"
            position="bottom"
            get-container="body">
            <van-datetime-picker show-toolbar
              type="date"
              title="请选择解除时间"
              :min-date="minDateTk"
              :max-date="maxDateTk"
              @cancel="RelieveTimeFend = false"
              @confirm="onRelieveTimeFend">
            </van-datetime-picker>
          </van-popup>
        </van-form>
      </div>
      <!-- 优抚人员 -->
      <div v-if="youfuShow">
        <van-form>
          <van-field v-model="yfForm.yfName"
            label="优抚类别"
            placeholder="请选择优抚类别"
            @click="youfuPicker = true"
            readonly="readonly"
            clickable />
          <van-popup v-model="youfuPicker"
            position="bottom">
            <van-picker show-toolbar
              :columns="youfuList"
              value-key="label"
              @confirm="youfuQr"
              @cancel="youfuPicker = false" />
          </van-popup>
          <van-field v-model="yfForm.DisabledLevel"
            name="DisabledLevel"
            label="伤残级别"
            placeholder="请输入伤残级别" />
          <van-field v-model="yfForm.EnlistedTime"
            name="EnlistedTime"
            label="入伍时间"
            @click="EnlistedTimeFend = true"
            readonly="readonly"
            placeholder="请选择入伍时间" />
          <van-popup v-model="EnlistedTimeFend"
            position="bottom"
            get-container="body">
            <van-datetime-picker show-toolbar
              type="date"
              title="请选择入伍时间"
              :min-date="minDateTk"
              :max-date="maxDateTk"
              @cancel="EnlistedTimeFend = false"
              @confirm="onEnlistedTimeFend">
            </van-datetime-picker>
          </van-popup>
          <van-field v-model="yfForm.RetreatTime"
            name="RetreatTime"
            label="退伍时间"
            @click="RetreatTimeFend = true"
            readonly="readonly"
            placeholder="请选择退伍时间" />
          <van-popup v-model="RetreatTimeFend"
            position="bottom"
            get-container="body">
            <van-datetime-picker show-toolbar
              type="date"
              title="请选择退伍时间"
              :min-date="minDateTk"
              :max-date="maxDateTk"
              @cancel="RetreatTimeFend = false"
              @confirm="onRetreatTimeFend">
            </van-datetime-picker>
          </van-popup>
        </van-form>
      </div>
      <!-- 吸毒人员 -->
      <div v-if="xdShow">
        <van-form>
          <van-field v-model="xdForm.NarcoticsKind"
            name="NarcoticsKind"
            label="吸食毒品种类"
            placeholder="请输入吸食毒品种类" />
          <van-field v-model="xdForm.RecviceTime"
            name="RecviceTime"
            label="接收时间"
            @click="xdRecviceTimeFend = true"
            readonly="readonly"
            placeholder="请选择接收时间" />
          <van-popup v-model="xdRecviceTimeFend"
            position="bottom"
            get-container="body">
            <van-datetime-picker show-toolbar
              type="date"
              title="请选择接收时间"
              :min-date="minDateTk"
              :max-date="maxDateTk"
              @cancel="xdRecviceTimeFend = false"
              @confirm="onxdRecviceTimeFend">
            </van-datetime-picker>
          </van-popup>
          <van-field v-model="xdForm.RelieveTime"
            name="RelieveTime"
            label="解除时间"
            @click="xdRelieveTimeFend = true"
            readonly="readonly"
            placeholder="请选择解除时间" />
          <van-popup v-model="xdRelieveTimeFend"
            position="bottom"
            get-container="body">
            <van-datetime-picker show-toolbar
              type="date"
              title="请选择解除时间"
              :min-date="minDateTk"
              :max-date="maxDateTk"
              @cancel="xdRelieveTimeFend = false"
              @confirm="onxdRetreatTimeFend">
            </van-datetime-picker>
          </van-popup>
        </van-form>
      </div>
      <!-- 邪教人员 -->
      <div v-if="xjShow">
        <van-form>
          <van-field v-model="xjForm.CultsKind"
            name="CultsKind"
            label="邪教种类"
            placeholder="请输入邪教种类" />
          <van-field v-model="xjForm.Circls"
            rows="1"
            name="Circls"
            label="情况说明"
            autosize
            type="textarea"
            placeholder="请输入情况说明" />
        </van-form>
      </div>
      <div style="margin: 16px">
        <van-button round
          block
          type="info"
          @click="SaveZh()"
          style="font-size: 16px"
          color="#617bfa">保存</van-button>
      </div>
    </van-form>
  </div>
</template>
<script>
import {
  GetResident,
  GetCodeEntrysList,
  SaveResidentOfFamily,
  SaveHPovertyStricken,
  SaveHLower,
  SaveHLowRent,
  SaveHSpecialHelper,
  SaveHPartMember,
  SaveHDisabled,
  SaveHVeterans,
  SaveHStudent,
  SaveHLetterVisits,
  SaveHCorrect,
  SaveHRelease,
  SaveHPriorHelper,
  SaveHDruger,
  SaveHCults
} from "@/api/wangge";
import Compressor from "compressorjs";
import axios from "axios";
import { getwgToken, setwgToken } from "@/utils/auth";
import Vue from "vue";
import { Toast } from "vant";
Vue.use(Toast);
export default {
  data () {
    return {
      minDate: new Date(1900, 0, 1),
      maxDate: new Date(2050, 10, 1),
      minDateTk: new Date(2010, 0, 1),
      maxDateTk: new Date(2050, 10, 1),
      currentDate: new Date(2021, 0, 17),
      listfrom: {
        hId: "",
      },
      hzlist: {
        Status: 1,
      },
      // 特困人员
      glForm: {
        HId: 0,
        TkType: "",
        ProviderName: "",
        ProviderTel: "",
        Relation: "",
        StartTime: "",
        Remark: "",
        Status: 1,
      },
      // 低保人员
      dbForm: {
        HId: 0,
        TkType: "",
        StartTime: "",
        EndTime: "",
        Circls: "",
        Remark: "",
        Status: 1,
      },
      // 廉租房人员
      lzForm: {
        HId: 0,
        TkType: "",
        Kind: "",
        RoomAddr: "",
        Remark: "",
        Status: 1,
      },
      // 特扶人员
      tfForm: {
        HId: 0,
        TkType: "",
        HelpKind: "",
        Remark: "",
        Status: 1,
      },
      // 保存党员信息
      dyForm: {
        HId: 0,
        TkType: "",
        PMKind: "",
        RelationAddr: "",
        InPartyTime: "",
        Remark: "",
        Status: 1,
      },
      // 保存残疾人员信息
      cjForm: {
        HId: 0,
        TkType: "",
        IsLimbs: "2",
        LimbsLevel: "",
        IsSpirit: "2",
        SpiritLevel: "",
        IsVision: "2",
        VisionLevel: "",
        IsHearing: "2",
        HearingLevel: "",
        IsIntellect: "2",
        IntellectLevel: "",
        IsLanguage: "2",
        LanguageLevel: "",
        Remark: "",
        Status: 1,
      },
      // 退役军人
      twForm: {
        HId: 0,
        JoinArmyTime: "",
        LeaveArmyTime: "",
        Status: 1,
        Remark: "",
      },
      // 在校生
      studentForm: {
        HId: 0,
        AttendStage: "",
        School: "",
        InTime: "",
        Status: 1,
        Remark: "",
      },
      // 在校生
      xfForm: {
        HId: 0,
        VisitsCircls: "",
        StableCircls: "",
        Status: 1,
        Remark: "",
      },
      // 矫正人员
      jzForm: {
        HId: 0,
        CrimeCircls: "",
        RecviceTime: "",
        RelieveTime: "",
        Status: 1,
        Remark: "",
      },
      //刑释人员
      xsForm: {
        HId: 0,
        RecviceTime: "",
        RelieveTime: "",
        Status: 1,
        Remark: "",
      },
      //优抚人员
      yfForm: {
        HId: 0,
        HelpKind: "",
        DisabledLevel: "",
        EnlistedTime: "",
        RetreatTime: "",
        Status: 1,
        Remark: "",
      },
      // 吸毒人员
      xdForm: {
        HId: 0,
        NarcoticsKind: "",
        RecviceTime: "",
        RelieveTime: "",
        Status: 1,
        Remark: "",
      },
      // 邪教人员
      xjForm: {
        HId: 0,
        CultsKind: "",
        Circls: "",
        Status: 1,
        Remark: "",
      },
      ryTypePicker: false, //人员类型
      recumenFend: false, //出生日期
      minzuPicker: false, //民族
      xueliPicker: false, //学历
      zongjiaoPicker: false, //宗教信仰
      hunyinPicker: false, //婚姻状况
      jibingPicker: false, //疾病状况
      bingyiPicker: false, //兵役情况
      zhengzhiPicker: false, //政治面貌
      guojiaPicker: false, //所属国家
      congyePicker: false, //从业状况
      hukouPicker: false, //户口状态
      guanxiPicker: false, //户主关系
      TkRenYuan: false, //特困人员
      DbRenYuan: false, //低保人员
      StartTimeFend: false, //特困开始时间
      EndTimeFend: false, //特困结束时间
      LzFang: false, //廉租房
      zhLbPicker: false, //租户类别
      tfPicker: false, //特扶人员
      dangyuanShow: false, //党员
      InPartyTimeFend: false, //入党时间
      cjShow: false, //残疾人员
      twShow: false, //退伍军人
      JoinArmyTimeFend: false, //入伍时间
      LeaveArmyTimeFend: false, //退伍时间
      studentShow: false, //大学生
      InTimeFend: false, //入学时间
      studentPicker: false,
      xfShow: false, //信访人员
      jzShow: false, //矫正人员
      jzRecviceTimeFend: false, //矫正接收时间
      jzRelieveTimeFend: false, //矫正解除时间
      xsShow: false, //刑释人员
      RecviceTimeFend: false, //刑释接收时间
      RelieveTimeFend: false, //刑释解除时间
      youfuShow: false, //优抚人员
      youfuPicker: false,
      EnlistedTimeFend: false, //优抚入伍时间
      RetreatTimeFend: false, //优抚退伍时间
      xdShow: false, //吸毒人员
      xdRecviceTimeFend: false,
      xdRelieveTimeFend: false,
      xdRecviceTime: false, //吸毒接收时间
      xdRelieveTime: false, //吸毒解除时间
      dyPicker: false,
      tfFang: false,
      xjShow: false,
      IdNumPass: true,//身份证号输入是否正确 true:正确
      minzu: [],
      zongjiao: [],
      xveli: [],
      hunyin: [],
      zhengzhi: [],
      hangye: [],
      zhiye: [],
      yuzhong: [],
      bingyi: [],
      guojia: [],
      congye: [],
      hukou: [],
      guanxi: [],
      dyList: [],
      Sex: "",
      IsOnlyChild: "",
      HealthyCircs: "",
      IsPartIn: "",
      ForbidReason: "",
      Status: "",
      jibingList: [
        { value: "01", label: "高血压" },
        { value: "02", label: "糖尿病" },
        { value: "03", label: "血糖异常" },
        { value: "04", label: "冠心病" },
        { value: "05", label: "慢性阻塞性肺疾病" },
        { value: "06", label: "恶性肿瘤" },
        { value: "07", label: "重型精神病" },
        { value: "08", label: "肝炎" },
        { value: "09", label: "其他" },
      ],
      ryTypeList: [
        { value: "1", label: "特困人员" },
        { value: "2", label: "低保人员" },
        { value: "3", label: "廉租房" },
        { value: "4", label: "特扶人员" },
        { value: "5", label: "党员" },
        { value: "6", label: "残疾人员" },
        { value: "7", label: "退役军人" },
        { value: "8", label: "大学生" },
        { value: "9", label: "信访人员" },
        { value: "10", label: "矫正人员" },
        { value: "11", label: "刑释人员" },
        { value: "12", label: "优抚人员" },
        { value: "13", label: "吸毒人员" },
        { value: "14", label: "邪教人员" },
      ],
      lzfList: [
        { value: "1", label: "享受补贴" },
        { value: "2", label: "实物配租" },
        { value: "3", label: "低收入" },
        { value: "4", label: "低保" },
      ],
      tfList: [
        { value: "1", label: "区级" },
        { value: "2", label: "市级" },
        { value: "3", label: "省级" },
        { value: "4", label: "国家级" },
      ],
      studentList: [
        { value: "1", label: "学前教育" },
        { value: "2", label: "小学" },
        { value: "3", label: "初中" },
        { value: "4", label: "大学" },
      ],
      youfuList: [
        { value: "1", label: "参战" },
        { value: "2", label: "涉核" },
        { value: "3", label: "伤残军人" },
        { value: "4", label: "复原军人" },
        { value: "5", label: "军转干" },
        { value: "6", label: "农村义务兵" },
        { value: "7", label: "无军籍" },
        { value: "8", label: "军烈属" },
      ],
      jtList: {
        HId: 0,
        BFaId: 0,
        HouserStatusCode: "",
        RelationHouserCode: "",
        Name: "",
        IdNumber: "",
        Sex: "",
        Birthday: "",
        MobileTel: "",
        Email: "",
        NationalityCode: "",
        RegisterAddr: "",
        OtherAddr: "",
        MarriageCode: "",
        EducationCode: "",
        PoliticalCode: "",
        MilitaryCode: "",
        ReligionCode: "",
        IsOnlyChild: "",
        IsPartIn: "",
        WorkStatusCode: "",
        OtherWorkStatus: "",
        Interest: "",
        Speciality: "",
        HealthyCircs: "",
        DiseaseCircs: "",
        OtherDisease: "",
        Status: "",
        ForbidReason: "",
        ForbidRemark: "",
        Remark: "",
      },
    };
  },
  created () {
    this.getList();
    this.zidianlist();
    // this.baiduToken()
  },
  methods: {
    baiduToken () {
      axios({
        url: 'https://aip.baidubce.com/oauth/2.0/token?grant_type=client_credentials&client_id=vwLKhMDOu11sKdvfWxrKFoHx&client_secret=a9uooS6st7gi9xV42sovyKNkhcBLOOuv',
        method: "post",
        // url:
        // '../baidu' +
        // "/oauth/2.0/token?grant_type=client_credentials&client_id=vwLKhMDOu11sKdvfWxrKFoHx&client_secret=a9uooS6st7gi9xV42sovyKNkhcBLOOuv",

        // data: filterData,
        headers: {
          "Content-Type": "Access-Control-Allow-Origin",
        },
      }).then((res) => {
        console.log(res.data.access_token);
        this.access_token = res.data.access_token;
      });
    },
    // 身份证识别
    afterReadBaidu (file, fileList) {
      var that = this;
      if (file.file.size > 1500000) {
        Toast.loading({
          duration: 0, // 持续展示 toast
          message: "正在识别...",
          forbidClick: true,
        });
        new Compressor(file.file, {
          quality: 0.6,
          success (result) {
            var formData = new FormData();
            formData.append("file", result, result.name);
            formData.append(
              // "access_token", this.access_token
              "access_token",
              "24.a03af8228bf55c79777bafb45279bba2.2592000.1654856054.282335-23075259"
            );
            console.log(file.content.split(",")[1]);
            formData.append("image", file.content.split(",")[1]);
            formData.append("id_card_side", "front");
            let filterData = {
              access_token: that.access_token,
              image: file.content.split(",")[1],
              id_card_side: "front",
            };

            axios({
              url: "https://aip.baidubce.com/rest/2.0/ocr/v1/idcard",
              // url: '../baidu' + "/rest/2.0/ocr/v1/idcard",
              method: "post",
              headers: {
                "Content-Type": "Access-Control-Allow-Origin",
              },
              data: formData,
            })
              .then((res) => {
                if (res.data.idcard_number_type == 1) {
                  Toast.success("识别成功");
                  Toast.clear();
                  that.hzlist.Name = res.data.words_result["姓名"].words;
                  that.hzlist.IdNumber =
                    res.data.words_result["公民身份号码"].words;
                  that.hzlist.RegisterAddr = res.data.words_result["住址"].words;
                  that.IdNum(res.data.words_result["公民身份号码"].words);
                } else {
                  Toast.clear();
                  Toast.fail("识别失败");
                }
              })
              .catch((res) => {
                console.log(res);
              });
          },
        });
      } else {
        var fileData = new FormData();
        fileData.append(
          // "access_token", this.access_token
          "access_token",
          "24.a03af8228bf55c79777bafb45279bba2.2592000.1654856054.282335-23075259"
        );
        fileData.append("image", file.content.split(",")[1]);
        fileData.append("id_card_side", "front");
        let filterData = {
          access_token: that.access_token,
          image: file.content.split(",")[1],
          id_card_side: "front",
        };
        Toast.loading({
          duration: 0, // 持续展示 toast
          message: "正在识别...",
          forbidClick: true,
        });
        axios({
          url: "https://aip.baidubce.com/rest/2.0/ocr/v1/idcard",
          // url: '../baidu' + "/rest/2.0/ocr/v1/idcard",
          method: "post",
          headers: {
            "Content-Type": "Access-Control-Allow-Origin",
          },
          data: fileData,
        })
          .then((res) => {
            if (res.data.idcard_number_type == 1) {
              Toast.clear();
              Toast.success("识别成功");

              that.hzlist.Name = res.data.words_result["姓名"].words;
              that.hzlist.IdNumber =
                res.data.words_result["公民身份号码"].words;
              that.hzlist.RegisterAddr = res.data.words_result["住址"].words;
              that.IdNum(res.data.words_result["公民身份号码"].words);
            } else {
              Toast.clear();
              Toast.fail("识别失败");
            }
          })
          .catch((res) => {
            // console.log(res);
          });
      }
    },
    // 获取住户列表
    getList () {
      console.log(this.$route.query);
      this.RyType = this.$route.query.add;
      if (this.$route.query.add == 1) {
        console.log(123);
        this.hzlist = {
          HId: 0,
          BFaId: this.$route.query.BFaId,
          BRId: this.$route.query.BRId,
          HouserStatusCode: "",
          RelationHouserCode: "",
          Name: "",
          IdNumber: "",
          Sex: "",
          Birthday: "",
          MobileTel: "",
          Email: "",
          NationalityCode: "",
          RegisterAddr: "",
          OtherAddr: "",
          MarriageCode: "",
          EducationCode: "",
          PoliticalCode: "",
          MilitaryCode: "",
          ReligionCode: "",
          IsOnlyChild: "2",
          IsPartIn: "",
          WorkStatusCode: "",
          OtherWorkStatus: "",
          Interest: "",
          Speciality: "",
          HealthyCircs: "",
          DiseaseCircs: "",
          OtherDisease: "",
          Status: "1",
          ForbidReason: "",
          ForbidRemark: "",
          Remark: "",
        };
      } else {
        console.log(321321);
        this.listfrom.hId = this.$route.query.HId;
        this.listfrom.accToken = getwgToken();
        GetResident(this.listfrom)
          .then((res) => {
            if (res.data.code == 0) {
              this.hzlist = res.data.data;
              // 性别
              this.hzlist.Sex = res.data.data.Sex + "";
              console.log(this.sex);
              // 独生子女
              this.hzlist.IsOnlyChild = res.data.data.IsOnlyChild + "";
              // 孕产妇
              this.hzlist.Gravida = res.data.data.Gravida + "";
              // 青少年
              this.hzlist.Teenagers = res.data.data.Teenagers + "";
              // 计划生育奖励扶助家庭
              this.hzlist.PlannedBirth = res.data.data.PlannedBirth + "";
              // 是否孤寡老人
              this.hzlist.LonelyOlder = res.data.data.LonelyOlder + "";
              // 是否空巢老人
              this.hzlist.EmptyOlder = res.data.data.EmptyOlder + "";
              // 是否独居老人
              this.hzlist.AloneOlder = res.data.data.AloneOlder + "";
              // 失能
              this.hzlist.Disability = res.data.data.Disability + "";
              // 半失能
              this.hzlist.HDisability = res.data.data.HDisability + "";
              // 健康状况
              this.hzlist.HealthyCircs = res.data.data.HealthyCircs + "";
              // 社区活动
              this.hzlist.IsPartIn = res.data.data.IsPartIn + "";
              // 禁用原因
              this.hzlist.ForbidReason = res.data.data.ForbidReason + "";
              // 状态
              this.hzlist.Status = res.data.data.Status + "";
              //出生日期
              this.currentDate = new Date(res.data.data.Birthday);
              //   for (var i = 0; i < this.dylist.length; i++) {
              //     var item = this.dylist[i].IssTime;
              //     item = this.dataFormat(item);
              //     this.dylist[i].IssTime = item;
              //     console.log();
              //     this.fwList()
              //   }
            }
          })
          .catch(() => { });
      }
    },
    onSubmit (values) {
      console.log("submit", values);
      console.log(this.hzlist);
    },
    SaveTk (row) {
      console.log(row);
      // console.log(row.HId);
      if (row == "1") {
        this.glForm.TkType = row;
        this.TkRenYuan = true;
        this.DbRenYuan = false;
        this.LzFang = false;
        this.tfFang = false;
        this.dangyuanShow = false;
        this.cjShow = false;
      } else if (row == "2") {
        this.dbForm.TkType = row;
        this.DbRenYuan = true;
        this.TkRenYuan = false;
        this.LzFang = false;
        this.tfFang = false;
        this.dangyuanShow = false;
        this.cjShow = false;
      } else if (row == "3") {
        this.lzForm.TkType = row;
        this.LzFang = true;
        this.TkRenYuan = false;
        this.DbRenYuan = false;
        this.tfFang = false;
        this.dangyuanShow = false;
        this.cjShow = false;
      } else if (row == "4") {
        this.tfForm.TkType = row;
        this.tfFang = true;
        this.TkRenYuan = false;
        this.DbRenYuan = false;
        this.LzFang = false;
        this.dangyuanShow = false;
        this.cjShow = false;
      } else if (row == "5") {
        this.dyForm.TkType = row;
        this.dangyuanShow = true;
        this.tfFang = false;
        this.TkRenYuan = false;
        this.DbRenYuan = false;
        this.LzFang = false;
        this.cjShow = false;
      } else if (row == "6") {
        this.cjForm.TkType = row;
        this.dangyuanShow = false;
        this.tfFang = false;
        this.TkRenYuan = false;
        this.DbRenYuan = false;
        this.LzFang = false;
        this.cjShow = true;
      }
      // this.glForm.TkType = row;
      // this.TkRenYuan = true;
      // this.DbRenYuan = false;
      // this.LzFang = false;
      // this.tfFang = false;
    },
    // 获取身份证
    IdNum (value) {
      console.log(value);
      var reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
      if (value.length > 0) {
        if (reg.test(value)) {
          this.go(value.length);
        } else {
          Toast.fail("身份证号码不正确");
        }
      }
    },
    go (val) {
      var iden =
        this.hzlist.IdNumber == ""
          ? this.hzlist.IdNumber
          : this.hzlist.IdNumber;
      // var iden = this.dataForms.IdNumber;
      console.log(iden);
      var id = iden.substring(0, 6);
      this.hzlist.NativeCantonCode = id;
      console.log(this.hzlist.NativeCantonCode);
      var sex = null;
      var birth = null;
      var myDate = new Date();
      var month = myDate.getMonth() + 1;
      var day = myDate.getDate();
      var age = 0;
      if (val === 18) {
        age = myDate.getFullYear() - iden.substring(6, 10) - 1;
        sex = iden.substring(16, 17);
        birth =
          iden.substring(6, 10) +
          "-" +
          iden.substring(10, 12) +
          "-" +
          iden.substring(12, 14);
        if (
          iden.substring(10, 12) < month ||
          (iden.substring(10, 12) == month && iden.substring(12, 14) <= day)
        )
          age++;
      }
      if (val === 15) {
        age = myDate.getFullYear() - iden.substring(6, 8) - 1901;
        sex = iden.substring(13, 14);
        birth =
          "19" +
          iden.substring(6, 8) +
          "-" +
          iden.substring(8, 10) +
          "-" +
          iden.substring(10, 12);
        if (
          iden.substring(8, 10) < month ||
          (iden.substring(8, 10) == month && iden.substring(10, 12) <= day)
        )
          age++;
      }
      if (sex % 2 == 0) {
        sex = 2;
        this.hzlist.Sex = sex + "";
        this.age = age;
        this.hzlist.Birthday = birth;
        this.currentDate = new Date(this.hzlist.Birthday);
      } else {
        sex = 1;
        // this.hzlist.Sex = sex;
        this.hzlist.Sex = sex + "";
        this.age = age;
        this.hzlist.Birthday = birth;
        this.currentDate = new Date(this.hzlist.Birthday);
      }
    },
    SaveZh () {
      console.log(this.cjForm);
      if (
        this.hzlist.HouserStatusName == "" ||
        this.hzlist.HouserStatusName == null ||
        this.hzlist.HouserStatusName == undefined
      ) {
        Toast.fail("请选择户口状态");
      } else if (
        this.hzlist.RelationHouserName == "" ||
        this.hzlist.RelationHouserName == null ||
        this.hzlist.RelationHouserName == undefined
      ) {
        Toast.fail("请选择户主关系");
      } else if (
        this.hzlist.Name == "" ||
        this.hzlist.Name == null ||
        this.hzlist.Name == undefined
      ) {
        Toast.fail("请输入姓名");
      } else if (

        this.hzlist.IdNumber == "" ||
        this.hzlist.IdNumber == null ||
        this.hzlist.IdNumber == undefined
      ) {
        Toast.fail("请输入身份证号");
      } else {
        this.hzlist.Sex = Number(this.hzlist.Sex);
        this.hzlist.IsOnlyChild = Number(this.hzlist.IsOnlyChild);
        this.hzlist.IsPartIn = Number(this.hzlist.IsPartIn);
        this.hzlist.ForbidReason = Number(this.hzlist.ForbidReason);
        this.hzlist.HealthyCircs = Number(this.hzlist.HealthyCircs);
        this.hzlist.Status = Number(this.hzlist.Status);
        this.hzlist.accToken = getwgToken();
        console.log(this.IdNumPass);
        var reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
        if (reg.test(this.hzlist.IdNumber)) {
          this.go(this.hzlist.IdNumber.length);
          SaveResidentOfFamily(this.hzlist).then((res) => {
            console.log(res);
            if (res.data.code == 0) {
              Toast.success("保存成功!");
              if (this.hzlist.ryTypeCode == "1") {
                this.tkRenyuan();
              } else if (this.hzlist.ryTypeCode == "2") {
                this.DBRenyuan();
              } else if (this.hzlist.ryTypeCode == "3") {
                this.LzfRenyuan();
              } else if (this.hzlist.ryTypeCode == "4") {
                this.TfRenyuan();
              } else if (this.hzlist.ryTypeCode == "5") {
                this.dangyuan();
              } else if (this.hzlist.ryTypeCode == "6") {
                this.canjiRY();
              } else if (this.hzlist.ryTypeCode == "7") {
                this.twRenyuan();
              } else if (this.hzlist.ryTypeCode == "8") {
                this.studentLing();
              } else if (this.hzlist.ryTypeCode == "9") {
                this.xFrenYuan();
              } else if (this.hzlist.ryTypeCode == "10") {
                this.jZrenYuan();
              } else if (this.hzlist.ryTypeCode == "11") {
                this.xSrenYuan();
              } else if (this.hzlist.ryTypeCode == "12") {
                this.yfrenYuan();
              } else if (this.hzlist.ryTypeCode == "13") {
                this.xdrenYuan();
              } else if (this.hzlist.ryTypeCode == "14") {
                this.xjrenYuan();
              }
              //   this.$router.push("/wangge/jiating");
              this.BRId = JSON.stringify(this.hzlist.BRId);
              console.log(this.BRId);
              this.$router.go(-1);
              // this.$router.push("/wangge/jiating/" + this.BRId + "/" + 0 + "/" + this.GId + "/" + this.Code);
              // this.$router.push({
              //   name: "jiating",
              //   query: { BRId: this.itemmark },
              // });
            } else {
              Toast.fail(res.data.msg);
            }
          });
        } else {
          Toast.fail("身份证号码不正确");
        }


      }
    },
    tkRenyuan: function () {
      console.log(this.hzlist);
      this.glForm.accToken = getwgToken();
      this.glForm.HId = this.hzlist.HId;
      SaveHPovertyStricken(this.glForm).then((res) => {
        console.log(res);
        if (res.data.code == 0) {
          Toast.success("保存成功!");
        } else {
          Toast.fail(res.data.msg);
        }
      });
    },
    DBRenyuan: function () {
      this.dbForm.accToken = getwgToken();
      this.dbForm.HId = this.hzlist.HId;
      SaveHLower(this.dbForm).then((res) => {
        console.log(res);
        if (res.data.code == 0) {
          Toast.success("保存成功!");
        } else {
          Toast.fail(res.data.msg);
        }
      });
    },
    // 廉租房
    LzfRenyuan: function () {
      this.lzForm.accToken = getwgToken();
      this.lzForm.HId = this.hzlist.HId;
      this.lzForm.Kind = this.lzForm.Kind;
      SaveHLowRent(this.lzForm).then((res) => {
        console.log(res);
        if (res.data.code == 0) {
          Toast.success("保存成功!");
        } else {
          Toast.fail(res.data.msg);
        }
      });
    },
    // 特扶
    TfRenyuan: function () {
      this.tfForm.accToken = getwgToken();
      this.tfForm.HId = this.hzlist.HId;
      this.tfForm.HelpKind = this.tfForm.HelpKind;
      SaveHSpecialHelper(this.tfForm).then((res) => {
        console.log(res);
        if (res.data.code == 0) {
          Toast.success("保存成功!");
        } else {
          Toast.fail(res.data.msg);
        }
      });
    },

    // 党员
    dangyuan: function () {
      this.dyForm.accToken = getwgToken();
      this.dyForm.HId = this.hzlist.HId;
      this.dyForm.PMKind = this.dyForm.PMKind;
      SaveHPartMember(this.dyForm).then((res) => {
        console.log(res);
        if (res.data.code == 0) {
          Toast.success("保存成功!");
        } else {
          Toast.fail(res.data.msg);
        }
      });
    },
    // 残疾人员
    canjiRY: function () {
      this.cjForm.accToken = getwgToken();
      this.cjForm.HId = this.hzlist.HId;
      this.cjForm.IsLimbs = Number(this.cjForm.IsLimbs);
      this.cjForm.IsSpirit = Number(this.cjForm.IsSpirit);
      this.cjForm.IsVision = Number(this.cjForm.IsVision);
      this.cjForm.IsHearing = Number(this.cjForm.IsHearing);
      this.cjForm.IsIntellect = Number(this.cjForm.IsIntellect);
      this.cjForm.IsLanguage = Number(this.cjForm.IsLanguage);
      // this.dyForm.PMKind = this.dyForm.PMKind;
      SaveHDisabled(this.cjForm).then((res) => {
        console.log(res);
        if (res.data.code == 0) {
          Toast.success("保存成功!");
        } else {
          Toast.fail(res.data.msg);
        }
      });
    },
    // 退役军人
    twRenyuan: function () {
      this.twForm.accToken = getwgToken();
      this.twForm.HId = this.hzlist.HId;
      SaveHVeterans(this.twForm).then((res) => {
        console.log(res);
        if (res.data.code == 0) {
          Toast.success("保存成功!");
        } else {
          Toast.fail(res.data.msg);
        }
      });
    },
    // 在校生
    studentLing: function () {
      this.studentForm.accToken = getwgToken();
      this.studentForm.HId = this.hzlist.HId;
      this.studentForm.AttendStage = this.studentForm.AttendStageName;
      SaveHStudent(this.studentForm).then((res) => {
        console.log(res);
        if (res.data.code == 0) {
          Toast.success("保存成功!");
        } else {
          Toast.fail(res.data.msg);
        }
      });
    },
    // 信访人员
    xFrenYuan: function () {
      this.xfForm.accToken = getwgToken();
      this.xfForm.HId = this.hzlist.HId;
      SaveHLetterVisits(this.xfForm).then((res) => {
        console.log(res);
        if (res.data.code == 0) {
          Toast.success("保存成功!");
        } else {
          Toast.fail(res.data.msg);
        }
      });
    },
    // 矫正人员
    jZrenYuan: function () {
      this.jzForm.accToken = getwgToken();
      this.jzForm.HId = this.hzlist.HId;
      SaveHCorrect(this.jzForm).then((res) => {
        console.log(res);
        if (res.data.code == 0) {
          Toast.success("保存成功!");
        } else {
          Toast.fail(res.data.msg);
        }
      });
    },
    // 刑释人员
    xSrenYuan: function () {
      this.xsForm.accToken = getwgToken();
      this.xsForm.HId = this.hzlist.HId;
      SaveHRelease(this.xsForm).then((res) => {
        console.log(res);
        if (res.data.code == 0) {
          Toast.success("保存成功!");
        } else {
          Toast.fail(res.data.msg);
        }
      });
    },
    // 优抚人员
    yfrenYuan: function () {
      this.yfForm.accToken = getwgToken();
      this.yfForm.HId = this.hzlist.HId;
      SaveHPriorHelper(this.yfForm).then((res) => {
        console.log(res);
        if (res.data.code == 0) {
          Toast.success("保存成功!");
        } else {
          Toast.fail(res.data.msg);
        }
      });
    },
    // 吸毒人员
    xdrenYuan: function () {
      this.xdForm.accToken = getwgToken();
      this.xdForm.HId = this.hzlist.HId;
      SaveHDruger(this.xdForm).then((res) => {
        console.log(res);
        if (res.data.code == 0) {
          Toast.success("保存成功!");
        } else {
          Toast.fail(res.data.msg);
        }
      });
    },
    // 邪教人员
    xjrenYuan: function () {
      this.xjForm.accToken = getwgToken();
      this.xjForm.HId = this.hzlist.HId;
      SaveHCults(this.xjForm).then((res) => {
        console.log(res);
        if (res.data.code == 0) {
          Toast.success("保存成功!");
        } else {
          Toast.fail(res.data.msg);
        }
      });
    },
    onrecumenFend (val) {
      //出生日期 确认
      this.hzlist.Birthday = this.dateformat(val);
      this.recumenFend = false;
    },
    onStartTimeFend (val) {
      //特困 开始时间确认
      this.dbForm.StartTime = this.dateformat(val);
      this.StartTimeFend = false;
    },
    onEndTimeFend (val) {
      //特困 结束时间确认
      this.dbForm.EndTime = this.dateformat(val);
      this.EndTimeFend = false;
    },
    onInPartyTimeFend (val) {
      //入党时间
      this.dyForm.InPartyTime = this.dateformat(val);
      this.InPartyTimeFend = false;
    },
    lianZuFQr (val) {
      console.log(val);
      //廉租房
      this.lzForm.Kind = val.value;
      this.lzForm.LzFName = val.label;
      this.zhLbPicker = false;
    },
    tfRenyuanQr (val) {
      console.log(val);
      //特扶人员
      this.tfForm.HelpKind = val.value;
      this.tfForm.tfName = val.label;
      this.tfPicker = false;
    },
    dangyuanQr (val) {
      console.log(val);
      //党员类别
      this.dyForm.PMKind = val.Coding;
      this.dyForm.PMName = val.Name;
      this.dyPicker = false;
    },
    onJoinArmyTimeFend (val) {
      //入伍时间确认
      this.twForm.JoinArmyTime = this.dateformat(val);
      this.JoinArmyTimeFend = false;
    },
    onLeaveArmyTimeFend (val) {
      //退伍时间确认
      this.twForm.LeaveArmyTime = this.dateformat(val);
      this.LeaveArmyTimeFend = false;
    },
    onInTimeFend (val) {
      //入学时间确认
      this.studentForm.InTime = this.dateformat(val);
      this.InTimeFend = false;
    },
    onjzRecviceTimeFend (val) {
      //矫正接收时间
      this.jzForm.RecviceTime = this.dateformat(val);
      this.jzRecviceTimeFend = false;
    },
    onjzRelieveTimeFend (val) {
      //矫正解除时间
      this.jzForm.RelieveTime = this.dateformat(val);
      this.jzRelieveTimeFend = false;
    },
    onRecviceTimeFend (val) {
      //刑释接收时间
      this.xsForm.RecviceTime = this.dateformat(val);
      this.RecviceTimeFend = false;
    },
    onRelieveTimeFend (val) {
      //刑释解除时间
      this.xsForm.RelieveTime = this.dateformat(val);
      this.RelieveTimeFend = false;
    },
    youfuQr (val) {
      console.log(val);
      //特扶人员
      this.yfForm.HelpKind = val.value;
      this.yfForm.yfName = val.label;
      this.youfuPicker = false;
    },
    onEnlistedTimeFend (val) {
      //优抚入伍时间
      this.yfForm.EnlistedTime = this.dateformat(val);
      this.EnlistedTimeFend = false;
    },
    onRetreatTimeFend (val) {
      //优抚退伍时间
      this.yfForm.RetreatTime = this.dateformat(val);
      this.RetreatTimeFend = false;
    },

    onxdRecviceTimeFend (val) {
      //吸毒接收时间
      this.xdForm.RecviceTime = this.dateformat(val);
      this.xdRecviceTimeFend = false;
    },
    onxdRetreatTimeFend (val) {
      //吸毒解除时间
      this.xdForm.RelieveTime = this.dateformat(val);
      this.xdRelieveTimeFend = false;
    },
    dateformat (val) {
      // 时间格式化事件 yy-mm-dd
      let year = val.getFullYear();
      let month = val.getMonth() + 1;
      let day = val.getDate();
      if (month >= 1 && month <= 9) {
        month = `0${month}`;
      }
      if (day >= 1 && day <= 9) {
        day = `0${day}`;
      }
      return `${year}-${month}-${day}`;
    },
    onrecumenANation (val) {
      console.log(val);
      //详情 民族
      this.hzlist.NationCode = val.Coding;
      this.hzlist.NationName = val.Name;
      this.minzuPicker = false;
    },
    //人员类型
    ryTypeQr (val) {
      console.log(val);
      this.TkRenYuan = false;
      this.DbRenYuan = false;
      this.LzFang = false;
      this.tfFang = false;
      this.dangyuanShow = false;
      this.cjShow = false;
      this.twShow = false;
      this.studentShow = false;
      this.xfShow = false;
      this.jzShow = false;
      this.xsShow = false;
      this.youfuShow = false;
      this.xdShow = false;
      this.xjShow = false;
      this.hzlist.ryTypeName = val.label;
      this.hzlist.ryTypeCode = val.value;
      this.ryTypePicker = false;
      if (this.hzlist.ryTypeCode == 1) {
        this.TkRenYuan = true;
      } else if (this.hzlist.ryTypeCode == 2) {
        this.DbRenYuan = true;
      } else if (this.hzlist.ryTypeCode == 3) {
        this.LzFang = true;
      } else if (this.hzlist.ryTypeCode == 4) {
        this.tfFang = true;
      } else if (this.hzlist.ryTypeCode == 5) {
        this.dangyuanShow = true;
      } else if (this.hzlist.ryTypeCode == 6) {
        this.cjShow = true;
      } else if (this.hzlist.ryTypeCode == 7) {
        this.twShow = true;
      } else if (this.hzlist.ryTypeCode == 8) {
        this.studentShow = true;
      } else if (this.hzlist.ryTypeCode == 9) {
        this.xfShow = true;
      } else if (this.hzlist.ryTypeCode == 10) {
        this.jzShow = true;
      } else if (this.hzlist.ryTypeCode == 11) {
        this.xsShow = true;
      } else if (this.hzlist.ryTypeCode == 12) {
        this.youfuShow = true;
      } else if (this.hzlist.ryTypeCode == 13) {
        this.xdShow = true;
      } else if (this.hzlist.ryTypeCode == 14) {
        this.xjShow = true;
      }
    },
    xueliList (val) {
      console.log(val);
      //学历
      this.hzlist.EducationCode = val.Coding;
      this.hzlist.EducationName = val.Name;
      this.xueliPicker = false;
    },
    zongjiaoList (val) {
      console.log(val);
      //宗教
      this.hzlist.ReligionCode = val.Coding;
      this.hzlist.ReligionName = val.Name;
      this.zongjiaoPicker = false;
    },
    hunyinList (val) {
      console.log(val);
      //婚姻
      this.hzlist.MarriageCode = val.Coding;
      this.hzlist.MarriageName = val.Name;
      this.hunyinPicker = false;
    },
    jibingQr (val) {
      console.log(val);
      //疾病情况
      this.hzlist.DiseaseCircs = val.label;
      this.hzlist.DiseaseName = val.value;
      this.jibingPicker = false;
    },
    bingyiQr (val) {
      console.log(val);
      //兵役情况
      this.hzlist.MilitaryCode = val.Coding;
      this.hzlist.MilitaryName = val.Name;
      this.bingyiPicker = false;
    },
    zhengzhiQr (val) {
      console.log(val);
      //政治面貌
      this.hzlist.PoliticalCode = val.Coding;
      this.hzlist.PoliticalName = val.Name;
      this.zhengzhiPicker = false;
    },
    guojiaQr (val) {
      console.log(val);
      //所属国家
      this.hzlist.NationalityCode = val.Coding;
      this.hzlist.NationalityName = val.Name;
      this.guojiaPicker = false;
    },
    congyeQr (val) {
      console.log(val);
      //疾病情况
      this.hzlist.WorkStatusCode = val.Coding;
      this.hzlist.WorkStatusName = val.Name;
      this.congyePicker = false;
    },
    hukouQr (val) {
      console.log(val);
      //户口状态
      this.hzlist.HouserStatusCode = val.Coding;
      this.hzlist.HouserStatusName = val.Name;
      this.hukouPicker = false;
    },
    guanxiQr (val) {
      console.log(val);
      //户口状态
      this.hzlist.RelationHouserCode = val.Coding;
      this.hzlist.RelationHouserName = val.Name;
      this.guanxiPicker = false;
    },
    //在校生
    studentQr (val) {
      console.log(val);
      //疾病情况
      this.studentForm.AttendStage = val.label;
      this.studentForm.AttendStageName = val.value;
      this.studentPicker = false;
    },
    // 字典查询
    zidianlist: function () {
      // 国家
      GetCodeEntrysList({ kind: "1" }).then((res) => {
        this.guojia = res.data.data;
      });
      // 民族
      GetCodeEntrysList({ kind: "3" }).then((res) => {
        this.minzu = res.data.data;
      });
      // 婚姻
      GetCodeEntrysList({ kind: "4" }).then((res) => {
        this.hunyin = res.data.data;
      });
      // 学历
      GetCodeEntrysList({ kind: "5" }).then((res) => {
        this.xveli = res.data.data;
      });
      // 政治面貌
      GetCodeEntrysList({ kind: "6" }).then((res) => {
        this.zhengzhi = res.data.data;
      });
      // 党员类别
      GetCodeEntrysList({ kind: "7" }).then((res) => {
        this.dyList = res.data.data;
      });
      // 宗教信息
      GetCodeEntrysList({ kind: "8" }).then((res) => {
        this.bingyi = res.data.data;
      });
      // 宗教信息
      GetCodeEntrysList({ kind: "9" }).then((res) => {
        this.zongjiao = res.data.data;
      });
      // 户口状态
      GetCodeEntrysList({ kind: "13" }).then((res) => {
        this.hukou = res.data.data;
      });
      // 户主关系
      GetCodeEntrysList({ kind: "14" }).then((res) => {
        this.guanxi = res.data.data;
      });
      // 从业状况
      GetCodeEntrysList({ kind: "201" }).then((res) => {
        this.congye = res.data.data;
      });
      // 行业
      GetCodeEntrysList({ kind: "202" }).then((res) => {
        this.hangye = res.data.data;
      });
      // 职业
      GetCodeEntrysList({ kind: "203" }).then((res) => {
        this.zhiye = res.data.data;
      });
      // 语种
      GetCodeEntrysList({ kind: "52" }).then((res) => {
        this.yuzhong = res.data.data;
      });
    },
  },
};
</script>
<style scoped>
.van-field {
  font-size: 17px !important;
}
.renType .van-button {
  margin: 10px;
  width: 85px;
  font-size: 16px;
}
</style>